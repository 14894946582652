<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-text-field
      v-if="searchEnabled"
      :label="$t('user.search')"
      color="secondary"
      hide-details
      style="max-width: 165px"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      v-if="totalNotifications"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ totalNotifications }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <v-list-item
          v-for="(n, i) in notifications"
          :key="`item-${i}`"
          @click="showNotifications"
        >
          <v-list-item-title v-text="n.text" />
        </v-list-item>
      </v-list>
    </v-menu>

    <div class="mx-3" />
    <locale-changer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav>
        <v-list-item @click="$router.push('/pages/user')">
          <v-list-item-title>{{ loggedInUser.name }} profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LocaleChanger from "./LocaleChanger";
// Utilities
import { mapState, mapMutations } from "vuex";

import notifications from "mixins/notifications";

export default {
  name: "DashboardCoreAppBar",
  components: {
    LocaleChanger,
  },
  mixins: [notifications],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    accentColor: "#f00",
    searchEnabled: false,
    languages: [
      {
        value: "en",
        text: "English",
      },
      {
        value: "de",
        text: "German",
      },
    ],
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  mounted() {
    this.get("/profile/notifications/").then((data) => {
      this.totalNotifications = data.total;
      this.notifications = data.latest.map(this.translate);
    });
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      localStorage.removeItem("loggedInUser");
      this.$store.commit("LOGOUT");
      this.post("/logout");
      this.$router.push("/login");
    },
    showNotifications() {
      this.$router.push("/pages/notifications");
    },
  },
};
</script>
